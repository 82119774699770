import React from 'react'
import { Link } from 'gatsby'

import './style.css'
import { rhythm, scale } from '../utils/typography'
import Header from './Header'


class Layout extends React.Component {
  render(props) {
    const { children, title, location } = this.props;
    return (
      <div>
      <Header location={location} title={title} />
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
        >
          {children}
        </div>
      </div>
      
      
    )
  }
}

export default Layout
