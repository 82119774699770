import Typography from 'typography'
import irvingTheme from 'typography-theme-irving'

// irvingTheme.overrideThemeStyles = () => ({
//   'a.gatsby-resp-image-link': {
//     boxShadow: 'none',
//   },
//   body: {
//     backgroundColor: '#444',
//     color: 'white',
//   }
// })

const typography = new Typography(irvingTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
