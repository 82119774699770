import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { rhythm, scale } from '../utils/typography'
// import Navbar from './Navbar'
import FmmIcon from '../assets/Full-Meta-Marketing-icon.png'

class Header extends React.Component {
  render () {
    const { title, location } = this.props
    let isContact

    if ( location.pathname === '/contact-us' || location.pathname === '/success') {
      isContact = ('none')
    } else {
      isContact = ('unset')
    }
  
  return (
    <div
      style={{
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"), 
        #24243d`,
        color: 'white',
      }}
    >
     <div style={{
        display: 'flex',
        padding: '1.5rem 0',
        margin: '0 1rem',
        alignItems: 'center',
      }} > 
        <div style={{maxWidth: '350px', margin: '0 1rem', display: 'flex'}} >
          <img src={FmmIcon}  style={{maxWidth: '50px', height: '50px', margin: '0 .5rem 0 0',}} />
          <h3 style={{margin: 0, display: 'flex', alignItems: 'center'}}><Link to="/" style={{
            textDecoration: 'none',
            color: 'inherit',
            textTransform: 'uppercase',
        }}>Full Meta Marketing</Link></h3>
        </div>
        {/* <Navbar/> */}
        </div>
        <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
        >
      <div>

      <h1
        style={{
          ...scale(2.25),
          fontFamily: 'Montserrat, sans-serif',
          marginTop: 0,
          marginBottom: rhythm(1),
          paddingBottom: rhythm(4),
          paddingTop: rhythm(4),
          textAlign: 'center',
        }}
      >
          {title}
      </h1>
      </div>
          <div style={{
            textAlign: 'center',
          }} >
            <ButtonCTA to="/contact-us" style={{display: isContact}}>Get Started</ButtonCTA></div>
        </div>
    </div>
  )
  }
}

const ButtonCTA = styled(Link)`
  padding: .5rem 1rem;
  margin: .5rem;
  background: linear-gradient(160deg, #FF4500 0%, #458495 80%);
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  font-family: 'Exo', sans-serif;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  font-size: 1rem;
`


export default Header
